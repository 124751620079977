// Import config
import { axiosInstance, Response } from './../config';
// Import utils
import createMultipart from '../../pages/administration/utils/createMultipart';
// Import types
import {
	Sport,
	SportGroups,
	Touched,
	sportImportParams,
	CreateSportImportParams,
	UpdateSportImportParams,
	Providers,
	SportsImportsData,
} from '../../pages/administration/sports/types';

export type SportType = 'REGULAR' | 'SPECIAL' | 'ALL';

export const sports = {
	getList(sportType: SportType = 'REGULAR', active: boolean = true, provider: string = ''): Promise<Sport[]> {
		return axiosInstance
			.get(`/sports/?active=${active}&sportType=${sportType}&idProvider=${provider}`)
			.then((res) => res.data);
	},
	getSpecialSports(): Promise<Sport[]> {
		return axiosInstance.get(`/sports/?active=${true}&sportType=${'SPECIAL'}`).then((res) => res.data);
	},
	getSpecialSportsByParent(idParent: string): Promise<Sport[]> {
		return axiosInstance
			.get(`/sports/?active=${true}&sportType=${'SPECIAL'}&idParent=${idParent}`)
			.then((res) => res.data);
	},
	getGroups(): Promise<SportGroups> {
		return axiosInstance.get(`/sports/?grouped=true&sportType=${'ALL'}`).then((res) => res.data);
	},
	reorderGroups({ item, index }: Touched): Response {
		return axiosInstance.put('/sports/reorder/group', {
			groupId: item,
			groupIndex: index,
		});
	},
	reorderSports({ item, index, group }: Touched): Response {
		return axiosInstance.put('/sports/reorder/' + item, {
			groupId: group,
			groupItemIndex: index,
		});
	},
	update(sport: Sport): Promise<Sport> {
		return axiosInstance
			.put('/sports/' + sport.id, createMultipart(sport), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},

	createSport(sport: Sport): Promise<Sport> {
		return axiosInstance
			.post(`/sports/create`, createMultipart(sport), {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	getSportPeriods(sportId: string): Promise<string[]> {
		return axiosInstance.get(`/sports/periods/${sportId}`).then((res) => res.data);
	},

	//Sports Ordering
	getSportsPositionForCompany(companyId: string) {
		return axiosInstance.get(`/sports/reorder/${companyId}`);
	},
	updateSportsPositionForCompany(companyId: string, params: any) {
		return axiosInstance.post(`/sports/reorder/${companyId}`, params);
	},

	getProviders(): Promise<Providers> {
		return axiosInstance.get('/administration/transform/providers').then((res) => res.data);
	},

	getSportImport(params: sportImportParams): Promise<SportsImportsData[]> {
		return axiosInstance.post(`/administration/transform/filter_sport_imports`, params).then((res) => res.data);
	},

	createSportImport(params: CreateSportImportParams): Promise<string[]> {
		return axiosInstance.post(`/administration/transform/sport_import`, params).then((res) => res.data);
	},

	updateSportImport(params: UpdateSportImportParams[]): Promise<SportsImportsData> {
		return axiosInstance.put(`/administration/transform/sport_import`, params).then((res) => res.data);
	},
};
