import { axiosInstance } from './../config';
import {
	Providers,
	TransformMapEntries,
	TransformMapEntriesParams,
} from './../../pages/administration/odd-types-mapping/types';

interface Response {
	errors: string[];
	mappings: {};
}

export const oddTypesMapping = {
	getProviders(): Promise<Providers> {
		return axiosInstance.get('/administration/transform/providers').then((res) => res.data);
	},

	getTransformMapEntries(params: TransformMapEntriesParams): Promise<TransformMapEntries[]> {
		return axiosInstance.post('/administration/transform/filter_transform', params).then((res) => res.data);
	},

	mapMarketsAndOdds(params: Record<string, Record<string, TransformMapEntries[]>>): Promise<Response> {
		return axiosInstance.post('/administration/transform/map_markets', params).then((res) => res.data);
	},
};
