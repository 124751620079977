import React, { Dispatch, FC, SetStateAction, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import { useNotify } from '../../../../../hooks/useNotifications';
import Delete from '../../../../../utils/svg/Delete';
import { useOnlineUser } from '../../../context/OnlineUserContext';

interface Props {
	edit: boolean;
	setEdit: Dispatch<SetStateAction<boolean>>;
	reset: () => void;
	isDisabled: boolean;
}

export const ProfileActions: FC<Props> = ({ edit, setEdit, reset, isDisabled }) => {
	const { t } = useTranslation();
	const { success, warn } = useNotify();
	const { onlineUser, setOnlineUser } = useOnlineUser();
	const { uid, dtAccountVerified, dtVerified, status } = onlineUser;

	function handleEdit() {
		setEdit(true);
	}

	function handleCancel() {
		reset();
		setEdit(false);
	}

	async function sendActivation() {
		try {
			await api.users.sendActivation(uid);
			success(t('toast.success.activationLinkSent'));
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.activationLinkProblem'));
		}
	}

	async function sendPasswordReset() {
		try {
			await api.users.sendPasswordReset(uid);
			success(t('toast.success.passwordResetSent'));
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.passwordResetProblem'));
		}
	}

	async function logout() {
		try {
			await api.users.logout(uid);
			success(t('toast.success.userLogout'));
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.userLogout'));
		}
	}

	async function verifyUser() {
		try {
			await api.users.verifyAccount(uid);
			setOnlineUser({ ...onlineUser, dtAccountVerified: new Date().toISOString() });
			success(t('toast.success.userVerified'));
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.userVerifyError'));
		}
	}

	async function finishRegistration() {
		try {
			await api.users.finishRegistration(uid);
			setOnlineUser({ ...onlineUser, status: 'ACTIVE' });
			success(t('toast.success.userVerified'));
			location.reload();
		} catch (err: any) {
			warn(err?.response?.data?.detail || t('toast.warn.userVerifyError'));
		}
	}

	const [opened, setOpen] = useState('');

	const confirmModalData = {
		logout: {
			message: 'logoutConfirm',
			action: logout,
		},
		activate: {
			message: 'activateConfirm',
			action: sendActivation,
		},
		reset: {
			message: 'resetConfirm',
			action: sendPasswordReset,
		},
		verify: {
			message: 'verifyConfirm',
			action: verifyUser,
		},
		finishRegistration: {
			message: 'finishRegistrationMsg',
			action: finishRegistration,
		},
	};

	return (
		<div className="shrink_1280 flex_direction_row padding_20">
			<div className="col-1"></div>
			<div className="col-10 flex_direction_row padding_right_40">
				{status === 'REGISTERED' && (
					<button className="btn lg blue outline " onClick={() => setOpen('finishRegistration')}>
						{t('usersAdministrations.finishRegistration')}
					</button>
				)}
				<button className="btn lg red outline " onClick={() => setOpen('logout')}>
					{t('usersAdministrations.logOutDevices')}
				</button>
				{dtVerified ? (
					<>
						<button className="btn lg blue outline" onClick={() => setOpen('reset')}>
							{t('usersAdministrations.sendPasswordReset')}
						</button>

						{!dtAccountVerified ? (
							<button className="btn lg blue outline" onClick={() => setOpen('verify')}>
								{t('usersAdministrations.verifyAccount')}
							</button>
						) : null}
					</>
				) : (
					<button className="btn lg blue outline" onClick={() => setOpen('activate')}>
						{t('usersAdministrations.sendActivation')}
					</button>
				)}
				{edit ? (
					<Fragment>
						{!isDisabled ? (
							<>
								<button
									className={`${isDisabled ? 'disabled' : ''} btn lg blue margin_left_auto`}
									form="hook-form"
									type="submit"
								>
									{t('general.save')}
								</button>
							</>
						) : (
							<button disabled={true} className={`btn lg round blue margin_left_auto`} onClick={() => {}}>
								<i className="mdi mdi-rotate-right mdi-spin" />
							</button>
						)}
						<button className="btn lg outline margin_left_5" form="hook-form" onClick={handleCancel}>
							{t('general.cancel')}
						</button>
					</Fragment>
				) : (
					<button className="btn lg blue margin_left_auto" form="hook-form" onClick={handleEdit}>
						{t('usersAdministrations.edit')}
					</button>
				)}
			</div>
			<div className="col-1"></div>
			{opened ? (
				<NotificationModal
					opened={true}
					cancelText={t('general.cancel')}
					confirmText={t('general.confirm')}
					cancelAction={() => {
						setOpen('');
					}}
					confirmAction={() => {
						confirmModalData[opened].action();
						setOpen('');
					}}
					zIndex={'over_3'}
				>
					<div className="context_icon margin_top_40">
						<Delete />
					</div>
					<h2 className="modal_message capitalize text_center">{t('general.areSure')}</h2>
					<p className="text_center margin_top_10">
						{t(`usersAdministrations.${confirmModalData[opened].message}`)}
					</p>
				</NotificationModal>
			) : null}
		</div>
	);
};
