import { axiosInstance } from '../config';
import { DailyExportTemplate } from '../../pages/Lists/daily-offer/types';
import { TimeAndDailyExportPreview } from '../../pages/Lists/daily-offer/types';

interface CreateParams {
	idListExportTemplate: number;
	sportTemplateMap: {
		idSport: string;
		idTemplate: string;
	}[];
	default: boolean;
}

export const fullExport = {
	getFullTemplates(): Promise<DailyExportTemplate[]> {
		return axiosInstance.get('/lists_export_administration/offer_template/full').then((res) => res.data);
	},

	getFullTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.get(`/lists_export_administration/offer_template/full/${templateId}`)
			.then((res) => res.data);
	},

	createFullTemplate(params: CreateParams): Promise<DailyExportTemplate> {
		return axiosInstance.post('/lists_export_administration/offer_template/full', params).then((res) => res.data);
	},

	updateFullTemplate(params: DailyExportTemplate): Promise<DailyExportTemplate> {
		return axiosInstance.put('/lists_export_administration/offer_template/full', params).then((res) => res.data);
	},

	deleteFullTemplate(templateId: number): Promise<DailyExportTemplate> {
		return axiosInstance
			.delete(`/lists_export_administration/offer_template/full?id=${templateId}`)
			.then((res) => res.data);
	},

	setDefault(templateId: number): Promise<DailyExportTemplate[]> {
		return axiosInstance
			.post(`/lists_export_administration/offer_template/full/set_default?id=${templateId}`)
			.then((res) => res.data);
	},

	getFullExportPreviewLinks(
		asPdf: boolean,
		ftpUpload: boolean
	): Promise<{ [key: string]: TimeAndDailyExportPreview }> {
		return axiosInstance
			.get(`/lists/timed_full_offer?as_pdf=${asPdf}&ftp_upload=${ftpUpload}`)
			.then((res) => res.data);
	},
	getTimeExportPreviewLinks(asPdf: boolean): Promise<{ [key: string]: TimeAndDailyExportPreview }> {
		return axiosInstance.get(`/lists/timed_full_offer?as_pdf=${asPdf}`).then((res) => res.data);
	},
};
