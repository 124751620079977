import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { useGeo } from '../../context';

interface Props {
	locked: string;
	rowItem: { id: string; value: any; inherited: any };
}

export const GeoDate: FC<Props> = ({ locked, rowItem }) => {
	const { updateGeoParameters } = useGeo();
	const { id, value, inherited } = rowItem;
	let inputValue = locked ? new Date(inherited) : value === null ? null : new Date(value);
	const handleChange = (date: Date) => {
		updateGeoParameters(id, date.toISOString());
	};

	return (
		<div className="value_content">
			<DatePicker
				className={'padding_6'}
				selected={inputValue}
				onChange={handleChange}
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={15}
				timeCaption="time"
				dateFormat="dd.MM.yy HH:mm"
				calendarClassName={'date-and-time'}
				minDate={new Date()}
			/>
		</div>
	);
};
